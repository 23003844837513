import React, { useState } from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.gif";

import Image from '../Elements/Image';
import Modal from '../Elements/Modal';

export default function Projects() {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   


  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Virtual World building Projects in Unity so far</h1>
            <p className="font13">
            Our virtual worlds were designed with interactivity in mind, allowing our audience to engage with their surroundings and experience the story on a whole new level. Whether it was a fully-realized game world or a 360-degree virtual tour, our virtual worlds were designed to captivate and engage.
            </p>
          </HeaderInfo>
          {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a
              data-video="https://player.vimeo.com/video/792842154"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/img/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/792842154"
            videoTag="iframe" />
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Enterprise Virtual World for Unilever's Risk and Controls"
                text="We built a virtual world for the Unilever Risk and Controls team that wanted a new and immersive way to introduce their department, make learning more engaging."
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Enterprise Virtual World for Unilever's Data Analytics"
                text="We built a immerisve game for the Unilever data analytics team that was driving the concept of brand trust and value to it's marketers. We also built an interactive 3D immersive data visualization dashboard for the CXOs."
                action={() => openModal()}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Brand Virtual World for Unilever's Marmite"
                text="We designed, and built a virtual scene for the brand marmite that uses immerisve tech to recreate the brand's famous marketing strategy of polarising it's customers but keeping it fun."
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Brand Virtual World for AMV"
                text="We built and added interactivity with out partners in Exolve for AMV's customer that wanted an immersive experience to interact with their potential interns."
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Shemaroo Theater"
                text="Our Filmrare cinema theater was converted into a Shemaroo theater and Bollywood movies were played in the metaverse for the first time."
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Project Metaniam"
                text="We built and added interactivity for our friends at Metaniam who were using the scene as the funnel top for their NFT project."
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company transition</h4>
              <h2 className="font40 extraBold">Hyper-focus on hyper-realistic builds</h2>
              <p className="font12">
                After an year long building on Unity framework we're moving to a Unreal based streaming platform at our new platform called Untropy. Filmrare will continue to be a studio where the creativity will continue to flow. We're focused on telling compelling stories for our brands using 3D and also will focus on more scripted content in 2023.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Visit Untropy" action={() => window.open("https://untropy3d.com")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  {/* <FullButton title="Contact Us" action={() => alert("clicked")} border /> */}
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
